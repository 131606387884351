/* eslint react/prop-types:0 */
import React, { useRef, useState } from 'react';
import cx from 'classnames';
import FormGroup from 'react-bootstrap/FormGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

import { HtmlLang, LabelLang } from 'src/lang/components';
import Input from 'src/components/core/controls/input';
import ErrorOverlay from 'src/components/errorOverlay';

const EyeStyled = styled.i`
  position: absolute;
  font-size: 16px;
  cursor: pointer;
  top: 30%;
  right: 10px;
`;

const inputField = props => {
  const {
    input, 
    type, 
    meta, 
    containerClassName,
    labelClassName, 
    labelText, 
    inputName, 
    className, 
    errorPlacement,
    zIndex, 
    isErrorOverlay = true,
    suffix,
    ...restProps
  } = props;
  const { onChange, onBlur, onFocus, value, name } = input;
  const [innerType, setInnerType] = useState(type);

  const target = useRef(null);
  const { error, touched, warning } = meta;
  const shouldShowError = !!(touched && error);
  const shouldShowWarning = !!(touched && warning);

  const handleClickEye = () => {
    setInnerType(innerType === type ? 'text' : type);
  };

  return (
    <FormGroup className='form-group'>
      <InputGroup>
        <div className={containerClassName || 'form-control'} ref={target}>
          {labelText && (<label className={labelClassName ||  ''}>{ <LabelLang id={labelText} /> }</label>)}
          <Input
            {...restProps}
            name={name || inputName}
            value={value}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={onChange}
            type={innerType}
            className={cx(className, shouldShowError ? 'border-danger' : '')}
          />
          {type === 'password' && <EyeStyled open={innerType === 'password'} className={`fa ${innerType === type ? 'fa-eye' : 'fa-eye-slash'}`} onClick={handleClickEye} />}
        </div>
        {suffix}
      </InputGroup>
      {
        isErrorOverlay ? (
          <>
            <ErrorOverlay placement={errorPlacement} target={target} shouldShowError={shouldShowError} error={error} zIndex={zIndex} />
            <ErrorOverlay placement={errorPlacement} target={target} shouldShowError={shouldShowWarning} error={warning} type="danger" zIndex={zIndex}/>
          </>
        ) : (
          <>
            { shouldShowError && <small className="text-danger"> <HtmlLang id={error} /> </small>}
            { shouldShowWarning && <small className="text-warning"> <HtmlLang id={warning} /> </small>}
          </>
        )
      }
    </FormGroup>
  );
};

export default inputField;
export inputValidator from './validator';
