import {LabelLang} from 'src/lang/components';
import React from 'react';

const isValidWalletAddress = message => walletAddress => {
  const isBech32 =  /^bc1/.test(walletAddress.toString());
  if(isBech32) {
    return (message ? message : <LabelLang id="home.constantLoan.errorMessages.notSupportBech32" />);
  }
  const a = walletAddress.toString().length > 0 && walletAddress.toString().length < 256;

  return (!a && (message ? message : <LabelLang id="home.constantLoan.errorMessages.inValidWalletAddress" />));
};

export default isValidWalletAddress;
