import React from 'react';
import styled from 'styled-components';
import BlockSection from 'src/components/blocksection';
import bgTop from './img/bgTop.png';

const AffiliateBlockSection = styled(BlockSection)`
  background-image: url(${bgTop}), linear-gradient(180deg, rgba(0,51,191,0.41) 0%, rgba(60,95,212,0.28) 100%);
  background-repeat: no-repeat;
  background-size: auto, cover;
  background-position: top center, bottom left;
  > div.container {
    padding: 0;
  }
  
  @media screen and (max-width: 768px) {
    width: 100vw;
  }
`;

export const AffiliateAssociationStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 15px;
  color: #FFFFFF;
  height: 40px;
  
  &:hover {
    cursor: pointer;
  }
  
  img {
    height: 40px;
    max-height: 40px;
  }
  
  .associationWith {
    font-size: 15px;
    margin: 0 10px;
    position:relative;
    &, &:hover, &:active {
      font-weight: 400;
      // color: ${props => props.isDark ? 'white' : '#000'};
    }
  }

  .affiliateLogo {
    font-weight: 700;
    font-size: 18px;
    // color: ${props => props.isDark ? 'white' : '#000'};
  }
`;

const AffiliateBanner = ({affiliateInfo}) => {
  return (
    <AffiliateBlockSection>
      {
        affiliateInfo && (
          <AffiliateAssociationStyled onClick={() => {window.open(affiliateInfo?.website_url, '_blank')}}>
            <div className="associationWith">In Association With </div>
            <div className="affiliateLogo">{affiliateInfo?.logo_url ? <img alt="" src={affiliateInfo?.logo_url} /> : affiliateInfo?.name }</div>
          </AffiliateAssociationStyled>
        )
      }
    </AffiliateBlockSection>
  );
};

export default AffiliateBanner;