import React from 'react';

import LoginForm from './loginForm';
import styles from './styles.scss';

export const VIEWS = {
  LOGIN: 'LOGIN',
  REGISTER: 'REGISTER',
  FORGET_PASSWORD: 'FORGET_PASSWORD'
};

export default function openLoginModal(actions, cb, data = null) {
  const close = () => actions.closeModal({ id: 'login' });
  return actions.openModal({
    id: 'login',
    modalProps: { centered: true, className: styles.container, backdrop: true },
    render: () => {
      return (
        <div className={styles.welcomeTitle}>
          <LoginForm
            modalActions={{ close }}
            data={data}
            onSuccess={(isRegister) => {
              close();
              if (typeof cb === 'function') cb(isRegister);
            }}
            onHandleCloseModal={() => {
              close();
            }}
            isPopup
          />
        </div>
      );
    },
  });
}
